<template>
  <div class="app-modal fixed inset-0 flex items-center justify-center py-40 z-10">
    <div ref="mask" class="mask absolute inset-0" :class="options.maskClass" @click="maskClicked" />
    <div class="content container relative overflow-y-auto bg-white">
      <img
        class="icon-cross absolute cursor-pointer"
        src="/imgs/cross.svg"
        :alt="$t('SHARED.CLOSE')"
        @click="$modal.close"
      />
      <component :is="$modal.component" v-bind="$modal.props" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  computed: {
    options() {
      return this.$modal.options
    },
  },
  created() {
    const { component } = this.$modal
    this.$options.components[component.name] = component
  },
  mounted() {
    if (this.options.closeOnEsc) {
      window.addEventListener('keydown', this.closeOnEsc, {
        passive: true,
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.closeOnEsc)
  },
  methods: {
    maskClicked(event) {
      if (event.target === this.$refs.mask && this.options.closeOnClickMask) {
        this.$modal.close()
      }
    },
    closeOnEsc(event) {
      if (event.key === 'Escape') {
        this.$modal.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-modal {
  .content {
    max-height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
      max-width: 680px;
    }

    @media (min-width: 992px) {
      max-width: 878px;
    }

    @media (min-width: 1200px) {
      max-width: 1062px;
    }
  }

  .icon-cross {
    top: 24px;
    right: 24px;
    width: 16px;
  }
}
</style>
